// @REPORTS
var Reports = {
    pieChart : {
        segmentEnlargmentFactor : 1.15,
        charts : {},// - elements
        chartData : {},//  -arrays of chart data
        selectedSectors : {},
        courseTbls : {},
        filters : [
            'N', 'I', 'P', 'ES', 'EX'
        ],

        indSegmentChanged : function ( chartID ) {
            Reports.pieChart.selectedSectors[chartID] = [];

            $('#'+chartID + ' .donut-sector').each ( function ( index, elem ) {
                if ( $(elem).attr('data-selected') === '1' ) {
                    Reports.pieChart.selectedSectors[chartID].push ( $(elem).attr('data-filter') );
                }
            });

            // console.log(Reports.pieChart.courseTbls[chartID]);
            // console.log('selectedSectors',Reports.pieChart.selectedSectors[chartID]);
            Reports.pieChart.courseTbls[chartID].draw();
        },

        siteSegmentChanged : function ( chartID, siteID ) {
            Reports.pieChart.selectedSectors[chartID] = [];

            $('#'+chartID + ' .donut-sector').each ( function ( index, elem ) {
                if ( $(elem).attr('data-selected') === '1' ) {
                    Reports.pieChart.selectedSectors[chartID].push ( $(elem).data('filter') );
                }
            });

            var status;

            for ( var i = 0; i < Reports.pieChart.filters.length; i++ ) {

                status = Reports.pieChart.filters[i];

                var indSegmentSelector  = '.ind-pc[data-site-id="' + siteID + '"] ' +
                    '.donut-sector[data-filter="' + status + '"]';

                if ( Reports.pieChart.selectedSectors[chartID].indexOf ( status ) !== -1 ) {

                    // - GROW THE INDIVIDUAL SEGMENTS
                    $(indSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOnBySelector ( $(elem) );
                    });

                }
                else {

                    // - SHRINK THE INDIVIDUAL SEGMENTS
                    $(indSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOffBySelector ( $(elem) );
                    });

                }
            }

            Reports.pieChart.filterAll();
        },

        overallSegmentChanged : function ( chartID ) {
            Reports.pieChart.selectedSectors[chartID] = [];

            $('#'+chartID + ' .donut-sector').each ( function ( index, elem ) {
                if ( $(elem).attr('data-selected') === '1' ) {
                    Reports.pieChart.selectedSectors[chartID].push ( $(elem).data('filter') );
                }
            });

            var status;

            for ( var i = 0; i < Reports.pieChart.filters.length; i++ ) {

                status = Reports.pieChart.filters[i];

                var siteSegmentSelector = '.site-pc .donut-sector[data-filter="' + status + '"]';
                var indSegmentSelector  = '.ind-pc .donut-sector[data-filter="' + status + '"]';

                if ( Reports.pieChart.selectedSectors[chartID].indexOf ( status ) !== -1 ) {
                    // - GROW THE SITE SEGMENTS
                    $(siteSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOnBySelector ( $(elem) );
                    });

                    // - GROW THE INDIVIDUAL SEGMENTS
                    $(indSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOnBySelector ( $(elem) );
                    });

                }
                else {
                    // - SHRINK THE SITE SEGMENTS
                    $(siteSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOffBySelector ( $(elem) );
                    });

                    // - SHRINK THE INDIVIDUAL SEGMENTS
                    $(indSegmentSelector).each ( function ( index, elem ) {
                        Reports.pieChart.segmentOffBySelector ( $(elem) );
                    });
                }
            }

            Reports.pieChart.filterAll();
        },

        filterAll : function() {
            // console.log('filterAll');
            // - THROUGH EACH INDIVIDUAL PIE CHART
            $('.ind-pc').each ( function ( SVGIndex, SVGElem ) {
                var chartID = $(SVGElem).attr ( 'id' );
                Reports.pieChart.selectedSectors[chartID] = [];

                $(SVGElem).find('.donut-sector[data-selected="1"]')
                    .each ( function ( segmentIndex, segmentElem ) {
                        var filterFlag = $(segmentElem).attr ( 'data-filter' );
                        Reports.pieChart.selectedSectors[chartID].push ( filterFlag );
                    });
                // console.log(chartID);
                // console.log(Reports.pieChart.selectedSectors[chartID]);
                Reports.pieChart.courseTbls[chartID].draw();
            });
        },

        reset : function ( chartID ) {
            // console.log('reset', $('#' + chartID).attr ( 'data-type' ));
            var siteID;

            switch ( $('#' + chartID).attr ( 'data-type' ) ) {
                case 'overall':
                    Reports.pieChart.overallReset ( chartID );
                    break;
                case 'site':
                    siteID = $('#' + chartID).attr ( 'data-site-id' );
                    Reports.pieChart.siteReset ( siteID );
                    break;
                case 'ind':
                    var courseID = $('#' + chartID).attr ( 'data-course-id' ),
                        instID   = $('#' + chartID).attr ( 'data-inst-id' );
                    siteID   = $('#' + chartID).attr ( 'data-site-id' );

                    Reports.pieChart.indReset ( courseID, instID, siteID );
                    break;
                default:
                    Reports.pieChart.overallReset ( chartID );
                    break;
            }
        },

        overallReset : function ( chartID ) {
            var segmentSelector = '#' + chartID + ' .donut-sector';
            $(segmentSelector).each ( function ( index, elem ) {
                Reports.pieChart.segmentOffBySelector ( $(elem) );
            });

            $('.site-pc').each ( function ( index, elem ) {
                Reports.pieChart.siteReset ( $(elem).attr ( 'data-site-id' ) );
            });
        },

        siteReset : function ( siteID ) {
            var segmentSelector = '.site-pc[data-site-id="' + siteID + '"] .donut-sector';
            $(segmentSelector).each ( function ( index, elem ) {
                Reports.pieChart.segmentOffBySelector ( $(elem) );
            });

            $('.ind-pc[data-site-id="' + siteID + '"]').each ( function ( index, elem ) {
                Reports.pieChart.indReset (
                    $(elem).attr ( 'data-course-id' ),
                    $(elem).attr ( 'data-inst-id' ),
                    $(elem).attr ( 'data-site-id' )
                );
            });
        },

        indReset : function ( courseID, instID, siteID ) {
            var segmentSelector = '.ind-pc' +
                '[data-course-id="' + courseID + '"]' +
                '[data-inst-id="' + instID + '"]' +
                '[data-site-id="' + siteID + '"]' +
                ' .donut-sector';

            $(segmentSelector).each ( function ( index, elem ) {
                Reports.pieChart.segmentOffBySelector ( $(elem) );
            });

            Reports.pieChart.filterAll();
        },

        toggleHandler : function ( chartID, index, elem, onChange ) {
            // console.log('selected', $(elem).data('selected'));
            // console.log(index, chartID, donutChart.charts[chartID].init.data[index], $(elem).attr('data-selected'));
            if ( $(elem).attr('data-selected') === '1' ) {
                $(elem).attr('data-selected', '0');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }
            else {
                $(elem).attr('data-selected', '1');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }

            Reports.pieChart.indSegmentChanged ( chartID );
        },

        siteToggleHandler : function ( chartID, index, elem, onChange ) {
            // console.log('selected', $(elem).data('selected'));
            // console.log(index, chartID, donutChart.charts[chartID].init.data[index], $(elem).attr('data-selected'));
            var siteID = chartID.substr ( chartID.lastIndexOf('-') + 1 );

            if ( $(elem).attr('data-selected') === '1' ) {
                $(elem).attr('data-selected', '0');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }
            else {
                $(elem).attr('data-selected', '1');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }

            Reports.pieChart.siteSegmentChanged ( chartID, siteID );
        },

        overallToggleHandler : function ( chartID, index, elem, onChange ) {
            // console.log('selected', $(elem).data('selected'));
            // console.log(index, chartID, donutChart.charts[chartID].init.data[index], $(elem).attr('data-selected'));
            if ( $(elem).attr('data-selected') === '1' ) {
                $(elem).attr('data-selected', '0');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }
            else {
                $(elem).attr('data-selected', '1');

                donutChart.charts[chartID].init.data[index].animatedOuterRadius = donutChart.charts[chartID].init.outerRadius;

                TweenMax.to ( donutChart.charts[chartID].init.data[index], 0.5, {
                    animatedOuterRadius : donutChart.charts[chartID].init.outerRadius * Reports.pieChart.segmentEnlargmentFactor,
                    onUpdate: function() {
                        var sectorObj = {
                            selector    : donutChart.charts[chartID].init.data[index].selector,
                            endD        : donutChart.charts[chartID].init.data[index].endD,
                            outerRadius : donutChart.charts[chartID].init.data[index].animatedOuterRadius,
                            innerRadius : donutChart.charts[chartID].init.innerRadius,
                            startD      : donutChart.charts[chartID].init.data[index].startD,
                            centrePoint : donutChart.charts[chartID].init.centrePoint,
                        };

                        donutChart.drawSector ( sectorObj );
                    }
                });
            }

            Reports.pieChart.overallSegmentChanged ( chartID );
        },

        segmentOnBySelector : function ( selector ) {
            var startD, endD, outerRadius, innerRadius, centrePoint, tweenPropArr = {};

            $(selector).each ( function ( index, elem ) {

                if ( $(elem).attr ( 'data-selected' ) === '0' ) {
                    // console.log('selector', $(elem).selector);
                    // console.log($(elem).attr('start-d'));
                    // console.log($(elem).attr('end-d'));
                    // console.log($(elem).parent().parent().attr('inner-radius'));
                    // console.log($(elem).parent().parent().attr('outer-radius'));
                    // console.log($(elem).parent().parent().attr('centre-point-x'));
                    // console.log($(elem).parent().parent().attr('centre-point-y'));

                    startD = Number ( $(elem).attr ( 'start-d' ) );
                    endD   = Number ( $(elem).attr ( 'end-d' ) );

                    // - MAKE IT SO WE CAN'T SWITCH SEGMENT ON IF ITS % VALUE IS 0
                    if ( endD - startD > 0 ) {

                        innerRadius = Number ( $(elem).parent().parent().attr ( 'inner-radius' ) );
                        outerRadius = Number ( $(elem).parent().parent().attr ( 'outer-radius' ) );
                        centrePoint = {
                            x : Number ( $(elem).parent().parent().attr ( 'centre-point-x' ) ),
                            y : Number ( $(elem).parent().parent().attr ( 'centre-point-y' ) ),
                        };

                        $(elem).attr ( 'data-selected', 1 );

                        tweenPropArr[index] = {
                            animatedOuterRadius : outerRadius,
                        };

                        // console.log(tweenPropArr);

                        TweenMax.to ( tweenPropArr[index], 0.5, {
                            animatedOuterRadius : outerRadius * Reports.pieChart.segmentEnlargmentFactor,
                            onUpdate : function ( index ) {
                                var sectorObj = {
                                    selector    : $(elem),
                                    endD        : endD,
                                    outerRadius : tweenPropArr[index].animatedOuterRadius,
                                    innerRadius : innerRadius,
                                    startD      : startD,
                                    centrePoint : centrePoint,
                                };

                                donutChart.drawSector ( sectorObj );
                            },
                            onUpdateParams: [index],
                        });

                    }
                }
            });
        },

        segmentOffBySelector : function ( selector ) {
            var startD, endD, outerRadius, innerRadius, centrePoint, tweenPropArr = {};

            $(selector).each ( function ( index, elem ) {
                if ( $(elem).attr ( 'data-selected' ) === '1' ) {
                    // console.log('selector', $(elem).selector);
                    // console.log($(elem).attr('start-d'));
                    // console.log($(elem).attr('end-d'));
                    // console.log($(elem).parent().parent().attr('inner-radius'));
                    // console.log($(elem).parent().parent().attr('outer-radius'));
                    // console.log($(elem).parent().parent().attr('centre-point-x'));
                    // console.log($(elem).parent().parent().attr('centre-point-y'));
                    $(elem).attr ( 'data-selected', 0 );

                    startD      = Number ( $(elem).attr ( 'start-d' ) );
                    endD        = Number ( $(elem).attr ( 'end-d' ) );
                    innerRadius = Number ( $(elem).parent().parent().attr ( 'inner-radius' ) );
                    outerRadius = Number ( $(elem).parent().parent().attr ( 'outer-radius' ) );
                    centrePoint = {
                        x : Number ( $(elem).parent().parent().attr ( 'centre-point-x' ) ),
                        y : Number ( $(elem).parent().parent().attr ( 'centre-point-y' ) ),
                    };

                    tweenPropArr[index] = {
                        animatedOuterRadius : outerRadius * Reports.pieChart.segmentEnlargmentFactor,
                    };

                    // console.log(tweenPropArr);

                    TweenMax.to ( tweenPropArr[index], 0.5, {
                        animatedOuterRadius : outerRadius,
                        onUpdate : function ( index ) {
                            var sectorObj = {
                                selector    : $(elem),
                                endD        : endD,
                                outerRadius : tweenPropArr[index].animatedOuterRadius,
                                innerRadius : innerRadius,
                                startD      : startD,
                                centrePoint : centrePoint,
                            };

                            donutChart.drawSector ( sectorObj );
                        },
                        onUpdateParams: [index],
                    });
                }
            });
        },

        makeOverallPieChart : function ( overallStatusCounts, overallTotal, chartData ) {
            if ( chartData !== undefined && overallTotal > 0 ) {
                chartID = 'pie-chart-overall';

                Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                Reports.pieChart.chartData.overall = chartData;

                Reports.pieChart.selectedSectors[chartID] = [];

                // - INSTANTIATE CHART
                donutChart.instantiate (
                    Reports.pieChart.charts[chartID],
                    chartID,
                    {
                        outerRadius : 120,
                        innerRadius : 0,
                        centrePoint : {
                            x : 0,
                            y : 150,
                        },
                        resetBtn : true,
                        labelMode : 'none',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                        animateIn : true,
                        data : Reports.pieChart.chartData.overall,
                        onComplete : function ( chartID ) {
                            donutChart.initToggleFilter ( chartID, Reports.pieChart.overallToggleHandler );
                            donutChart.initResetBtn ( chartID );
                            TweenMax.to ( '.sector-label', 0.5, {opacity:1} );
                        },
                        onCompleteParams : [chartID],
                    }
                );
            }
        },

        makeSitePieCharts : function ( siteCharts ) {
            var chartID, chartPresent;

            for ( var siteID in siteCharts ) {
                chartID = 'pie-chart-site-' + siteID;

                chartPresent = $('#' + chartID).length > 0;

                if ( chartPresent ) {

                    Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                    if ( siteCharts[siteID].totalRecords > 0 ) {

                        Reports.pieChart.chartData['sites-' + siteID] = siteCharts[siteID].chartData;

                        Reports.pieChart.selectedSectors[chartID] = [];

                        // - INSTANTIATE CHART
                        donutChart.instantiate (
                            Reports.pieChart.charts[chartID],
                            chartID,
                            {
                                outerRadius : 70,
                                innerRadius : 0,
                                centrePoint : {
                                    x : 10,
                                    y : 150,
                                },
                                resetBtn : true,
                                labelMode : 'none',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                                animateIn : true,
                                data : Reports.pieChart.chartData['sites-' + siteID],
                                onComplete : function ( chartID ) {
                                    donutChart.initToggleFilter ( chartID, Reports.pieChart.siteToggleHandler );
                                    donutChart.initResetBtn ( chartID );
                                    TweenMax.to ( '.sector-label', 0.5, {opacity:1} );
                                },
                                onCompleteParams : [chartID],
                            }
                        );
                    }
                    else {
                        // console.log('no data', chartID, Reports.pieChart.charts[chartID]);
                        donutChart.addNoDataGraphic ( Reports.pieChart.charts[chartID] );
                    }
                }
            }
        },
    },
    OL : {

        init : function() {
            $('#ms-courses')
                .off ( 'change' )
                .on ( 'change', function ( evt ) {
                    if ( $(this).val().indexOf ( '-' ) !== -1 ) {
                        $(this).find ( 'option' ).each ( function ( index, elem ) {
                            if ( $(elem).attr ( 'value' ) === '-' ) {
                                $(elem).prop ( 'selected', false );
                            }
                            else {
                                $(elem).prop ( 'selected', true );
                            }
                        });
                    }
                });

            $('#completed-date-range').dateRange({
                mainLabel : 'Completed Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });

            $('#renewal-date-range').dateRange({
                mainLabel : 'Deadline / Renewal Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });
        },

        run : function ( filters ) {
            // - reports.OL.run
            var	reportSRC = $('script#report-elearning-tpl').html(),
                reportTPL = Handlebars.compile ( reportSRC ),
                reportHTM;

            if ( filters.courses !== null && filters.locations !== null ) {
                console.log(filters);
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/elearning-html',
                    data: sendData,
                    type: 'post',
                    dataType: 'text',
                    success: function ( returnedData ) {
                        console.log('raw',returnedData);
                        returnedData = JSON.parse ( returnedData );
                        console.log('parsed', returnedData);
                        // GET THE TERM FOR SITES
                        returnedData.data.terms_tier_2_p = terms.terms_tier_2_p;
                        // console.log(returnedData);
                        if ( returnedData.success ) {
                            var chartID, tableKey;
                            reportHTM = reportTPL ( returnedData.data );
                            // console.log(reportHTM);
                            $('.report-result').html ( reportHTM );

                            // - GET A LINK FOR PRINT ALL
                            $('.report-result').find('#print-all-ol-btn').attr (
                                'href', Reports.OL.getPrintAllLink ( reportHTM )
                            );

                            Reports.OL.destroyTables();

                            Reports.pieChart.courseTbls      = {};
                            Reports.pieChart.selectedSectors = {};
                            Reports.pieChart.charts          = {};
                            Reports.pieChart.chartData       = {};

                            $.fn.dataTable.ext.search = [];

                            Reports.pieChart.makeOverallPieChart (
                                returnedData.data.overallChart.statusCounts,
                                returnedData.data.overallChart.total,
                                returnedData.data.overallChart.chartData
                            );

                            Reports.pieChart.makeSitePieCharts (
                                returnedData.data.siteCharts
                            );

                            Reports.OL.makeIndPieCharts (
                                returnedData.data.courses
                            );

                            $('.print-section-btn')
                                .off('click')
                                .on('click', function(evt) {
                                    var courseID = $(this).attr('data-course-id'),
                                        instID = $(this).attr('data-inst-id'),
                                        siteID = $(this).attr('data-site-id'),
                                        chartID = 'pie-chart-' + courseID + '-' + instID + '-' + siteID,
                                        statusesSelected,
                                        archiveStatus = $('.archive-status-filter input[name="archive-status"]:checked').val(),
                                        completedDateRange = $(this).attr ( 'data-completed-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-completed-date-range' ),
                                        renewalDateRange = $(this).attr ( 'data-renewal-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-renewal-date-range' ),
                                        href = '/reports/print-single-ol/' + courseID + '/' + instID + '/' + siteID + '/' + archiveStatus;

                                    // - IF NO SELECTED SECTORS WE SET IT TO ALL
                                    if (Reports.pieChart.selectedSectors[chartID].length === 0) {
                                        statusesSelected = ['N','I','P','ES','EX'];
                                    } else {
                                        statusesSelected = Reports.pieChart.selectedSectors[chartID];
                                    }

                                    if (statusesSelected !== undefined && statusesSelected.length > 0) {
                                        href += '/' + statusesSelected.join('-');
                                    }

                                    href += '/' + completedDateRange + '/' + renewalDateRange;
                                    // console.log(href);
                                    window.open ( href );
                                });
                        }
                    },
                    error: function(err) {
                        console.log(err);
                    }
                });
            } else {
                main.flashData.show(
                    'Please select at least one course and at least one location to generate a report',
                    'negative',
                    7
                );
            }
        },

        runHelpGuide : function ( filters ) {
            // - reports.OL.run
            var reportSRC = $('script#report-elearning-tpl').html(),
                reportTPL = Handlebars.compile ( reportSRC ),
                reportHTM;

            if ( filters.courses !== null && filters.locations !== null ) {
                // console.log(filters);
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/elearning-html-help-guide',
                    data: sendData,
                    type: 'post',
                    dataType: 'text',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        returnedData = JSON.parse ( returnedData );
                        // console.log(returnedData);
                        // GET THE TERM FOR SITES
                        returnedData.data.terms_tier_2_p = terms.terms_tier_2_p;
                        // console.log(returnedData);
                        if ( returnedData.success ) {
                            var chartID, tableKey;
                            reportHTM = reportTPL ( returnedData.data );
                            // console.log(reportHTM);
                            $('.report-result').html ( reportHTM );

                            // - GET A LINK FOR PRINT ALL
                            $('.report-result').find('#print-all-ol-btn').attr (
                                'href', Reports.OL.getPrintAllLink ( reportHTM )
                            );

                            Reports.OL.destroyTables();

                            Reports.pieChart.courseTbls      = {};
                            Reports.pieChart.selectedSectors = {};
                            Reports.pieChart.charts          = {};
                            Reports.pieChart.chartData       = {};

                            $.fn.dataTable.ext.search = [];

                            Reports.pieChart.makeOverallPieChart (
                                returnedData.data.overallChart.statusCounts,
                                returnedData.data.overallChart.total,
                                returnedData.data.overallChart.chartData
                            );

                            Reports.pieChart.makeSitePieCharts (
                                returnedData.data.siteCharts
                            );

                            Reports.OL.makeIndPieCharts (
                                returnedData.data.courses
                            );

                            $('.print-section-btn')
                                .off ( 'click' )
                                .on  ( 'click', function ( evt ) {
                                    var courseID = $(this).attr ( 'data-course-id' ),
                                        instID   = $(this).attr ( 'data-inst-id' ),
                                        siteID   = $(this).attr ( 'data-site-id' ),
                                        chartID = 'pie-chart-' + courseID + '-' + instID + '-' + siteID,
                                        statusesSelected,
                                        archiveStatus = $('.archive-status-filter input[name="archive-status"]:checked').val(),
                                        completedDateRange = $(this).attr ( 'data-completed-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-completed-date-range' ),
                                        renewalDateRange = $(this).attr ( 'data-renewal-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-renewal-date-range' ),
                                        href = '/reports/print-single-ol/' + courseID + '/' + instID + '/' + siteID + '/' + archiveStatus;

                                    // - IF NO SELECTED SECTORS WE SET IT TO ALL
                                    if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                        statusesSelected = ['N','I','P','ES','EX'];
                                    }
                                    else {
                                        statusesSelected = Reports.pieChart.selectedSectors[chartID];
                                    }

                                    if ( statusesSelected !== undefined && statusesSelected.length > 0 ) {
                                        href += '/' + statusesSelected.join('-');
                                    }

                                    href += '/' + completedDateRange + '/' + renewalDateRange;
                                    // console.log(href);
                                    window.open ( href );
                                });
                        }
                    },
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },

        destroyTables : function() {
            for ( var chartID in Reports.pieChart.courseTbls ) {
                Reports.pieChart.courseTbls[chartID].destroy();
            }
        },

        getPrintAllLink : function ( reportHTM ) {
            var courseIDs = [],
                instIDs   = [],
                siteIDs   = [],
                completedDateRange = 'null',// - THESE MUST BE STRINGS AS WE'RE RETURNING THEM AS URL ARGUMENTS
                renewalDateRange   = 'null';

            $('section.report-section').each ( function ( index, elem ) {
                if ( courseIDs.indexOf ( $(elem).attr ( 'data-course-id' ) ) === -1 ) {
                    courseIDs.push ( $(elem).attr ( 'data-course-id' ) );
                }

                if ( instIDs.indexOf ( $(elem).attr ( 'data-inst-id' ) ) === -1 ) {
                    instIDs.push ( $(elem).attr ( 'data-inst-id' ) );
                }

                if ( siteIDs.indexOf ( $(elem).attr ( 'data-site-id' ) ) === -1 ) {
                    siteIDs.push ( $(elem).attr ( 'data-site-id' ) );
                }
            });

            if ( $('#completed-date-range-value').val() !== '' ) {
                completedDateRange = $('#completed-date-range-value').val();
            }

            if ( $('#renewal-date-range-value').val() !== '' ) {
                renewalDateRange = $('#renewal-date-range-value').val();
            }

            var href  = '/reports/print-all-ol';
            href += '/' + courseIDs.join('-');
            href += '/' + instIDs.join('-');
            href += '/' + siteIDs.join('-');
            href += '/' + $('.archive-status-filter input[name="archive-status"]:checked').val();
            href += '/null'; // - NEEDS TO BE PASSED NULL FOR NOW, ARG IS EXPECTED BEFORE DATE RANGES
            href += '/' + completedDateRange;
            href += '/' + renewalDateRange;

            return href;
        },

        makeIndPieCharts : function ( courses ) {
            var chartID, tableID;

            for ( var courseID in courses ) {
                for ( var instID in courses[courseID].locations ) {
                    for ( var siteID in courses[courseID].locations[instID].sites ) {

                        chartID = 'pie-chart-' + courseID + '-' + instID + '-' + siteID;
                        tableID = 'dt-el-report-' + courseID + '-' + instID + '-' + siteID;

                        Reports.pieChart.courseTbls[chartID] = $('#' + tableID).DataTable({
                            paging : false,
                            language : {
                                search : 'Filter Results',
                            },
                            columnDefs : [
                                {
                                    orderable : false,
                                    targets : [-3],
                                },
                                {
                                    width : 80,
                                    targets : [0],
                                },
                                {
                                    width : 80,
                                    targets : [1],
                                },
                                {
                                    width : 80,
                                    targets : [2],
                                },
                                {
                                    width : 40,
                                    targets : [3],
                                },
                            ],
                            order : [[ 1, "asc" ]]
                        });

                        if ( courses[courseID].locations[instID].sites[siteID].chartData !== undefined ) {

                            Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                            Reports.pieChart.chartData[courseID + '-' + instID + '-' + siteID] = courses[courseID].locations[instID].sites[siteID].chartData;

                            Reports.pieChart.selectedSectors[chartID] = [];

                            // - INSTANTIATE CHART
                            donutChart.instantiate(
                                Reports.pieChart.charts[chartID],
                                chartID,
                                {
                                    outerRadius : 70,
                                    innerRadius : 0,
                                    centrePoint : {
                                        x : 130,
                                        y : 100,
                                    },
                                    resetBtn : true,
                                    labelMode : 'legend',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                                    animateIn : true,
                                    data : Reports.pieChart.chartData[courseID + '-' + instID + '-' + siteID],
                                    onComplete : function ( chartID ) {
                                        donutChart.initToggleFilter ( chartID, Reports.pieChart.toggleHandler );
                                        donutChart.initResetBtn ( chartID );
                                        TweenMax.to ( '.sector-label', 0.5, {opacity:1} );

                                        $.fn.dataTable.ext.search.push(
                                            function( settings, data, dataIndex ) {
                                                var chartID = 'pie-chart-' +
                                                    $(settings.nTable).attr('data-course-id') + '-' +
                                                    $(settings.nTable).attr('data-inst-id')   + '-' +
                                                    $(settings.nTable).attr('data-site-id');
                                                if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                                    return true;
                                                }

                                                for ( var i = 0; i < Reports.pieChart.selectedSectors[chartID].length; i++ ) {
                                                    if ( data[3].indexOf ( 'status::' + Reports.pieChart.selectedSectors[chartID][i] ) !== -1 ) {
                                                        return true;
                                                    }
                                                }

                                                return false;
                                            }
                                        );
                                    },
                                    onCompleteParams : [chartID],
                                }
                            );
                            // - /INSTANTIATE CHART
                        }
                    }
                }
            }
        },

        runExcel : function ( filters, onSuccess ) {
            // console.log(filters);
            if ( filters.courses !== null && filters.locations !== null ) {
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/elearning-excel',
                    data: sendData,
                    type: 'post',
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        // returnedData = JSON.parse ( returnedData );
                        // console.log(returnedData);
                        if ( returnedData.success ) {
                            onSuccess ( returnedData );
                        }
                    },
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },
    },

    ILT : {

        init : function() {
            $('#ms-courses')
                .off ( 'change' )
                .on ( 'change', function ( evt ) {
                    if ( $(this).val().indexOf ( '-' ) !== -1 ) {
                        $(this).find ( 'option' ).each ( function ( index, elem ) {
                            if ( $(elem).attr ( 'value' ) === '-' ) {
                                $(elem).prop ( 'selected', false );
                            }
                            else {
                                $(elem).prop ( 'selected', true );
                            }
                        });
                    }
                });

            $('#completed-date-range').dateRange({
                mainLabel : 'Completed Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });

            $('#renewal-date-range').dateRange({
                mainLabel : 'Deadline / Renewal Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });
        },

        run : function ( filters ) {
            // - reports.ILT.run
            var	reportSRC = $('script#report-ilt-courses-tpl').html(),
                reportTPL = Handlebars.compile ( reportSRC ),
                reportHTM;

            if ( filters.courses !== null && filters.locations !== null ) {

                var sendData = filters;
                // console.log(sendData);
                $.ajax({
                    url: '/admin/AJAX-reports/ilt-courses-html',
                    data: sendData,
                    type: 'post',
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        // returnedData = JSON.parse ( returnedData );
                        // console.log(returnedData);
                        // GET THE TERM FOR SITES
                        returnedData.data.terms_tier_2_p = terms.terms_tier_2_p;
                        if ( returnedData.success ) {
                            var chartID, tableKey;

                            reportHTM = reportTPL ( returnedData.data );

                            //console.log(reportHTM);
                            $('.report-result').html ( reportHTM );

                            // - GET A LINK FOR PRINT ALL
                            $('.report-result').find('#print-all-ilt-btn').attr (
                                'href', Reports.ILT.getPrintAllLink ( reportHTM )
                            );

                            if ( returnedData.data.overallChart.total > 0 ) {
                                Reports.ILT.destroyTables();

                                Reports.pieChart.courseTbls      = {};
                                Reports.pieChart.selectedSectors = {};
                                Reports.pieChart.charts          = {};
                                Reports.pieChart.chartData       = {};

                                $.fn.dataTable.ext.search = [];

                                Reports.pieChart.makeOverallPieChart (
                                    returnedData.data.overallChart.statusCounts,
                                    returnedData.data.overallChart.total,
                                    returnedData.data.overallChart.chartData
                                );

                                Reports.pieChart.makeSitePieCharts (
                                    returnedData.data.siteCharts
                                );

                                Reports.ILT.makeIndPieCharts (
                                    returnedData.data.courses
                                );

                                $('.print-section-btn')
                                    .off ( 'click' )
                                    .on  ( 'click', function ( evt ) {
                                        var courseID = $(this).attr ( 'data-course-id' ),
                                            instID   = $(this).attr ( 'data-inst-id' ),
                                            siteID   = $(this).attr ( 'data-site-id' ),
                                            chartID = 'pie-chart-' + courseID + '-' + instID + '-' + siteID,
                                            statusesSelected,
                                            archiveStatus = $('.archive-status-filter input[name="archive-status"]:checked').val(),
                                            completedDateRange = $(this).attr ( 'data-completed-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-completed-date-range' ),
                                            renewalDateRange = $(this).attr ( 'data-renewal-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-renewal-date-range' ),
                                            href = '/reports/print-single-ilt/' + courseID + '/' + instID + '/' + siteID + '/' + archiveStatus;

                                        // - IF NO SELECTED SECTORS WE SET IT TO ALL
                                        if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                            statusesSelected = ['N','I','P','ES','EX'];
                                        }
                                        else {
                                            statusesSelected = Reports.pieChart.selectedSectors[chartID];
                                        }

                                        if ( statusesSelected !== undefined && statusesSelected.length > 0 ) {
                                            href += '/' + statusesSelected.join('-');
                                        }

                                        href += '/' + completedDateRange + '/' + renewalDateRange;

                                        // console.log(href);
                                        window.open ( href );
                                    });

                            }
                        }
                    },
                    error: Errors.ajaxErrorHandler,
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },

        destroyTables : function() {
            for ( var chartID in Reports.pieChart.courseTbls ) {
                Reports.pieChart.courseTbls[chartID].destroy();
            }
        },

        getPrintAllLink : function ( reportHTM ) {
            var courseIDs          = [],
                instIDs            = [],
                siteIDs            = [],
                completedDateRange = 'null',// - THESE MUST BE STRINGS AS WE'RE RETURNING THEM AS URL ARGUMENTS
                renewalDateRange   = 'null';

            $('section.report-section').each ( function ( index, elem ) {
                if ( courseIDs.indexOf ( $(elem).attr ( 'data-course-id' ) ) === -1 ) {
                    courseIDs.push ( $(elem).attr ( 'data-course-id' ) );
                }

                if ( instIDs.indexOf ( $(elem).attr ( 'data-inst-id' ) ) === -1 ) {
                    instIDs.push ( $(elem).attr ( 'data-inst-id' ) );
                }

                if ( siteIDs.indexOf ( $(elem).attr ( 'data-site-id' ) ) === -1 ) {
                    siteIDs.push ( $(elem).attr ( 'data-site-id' ) );
                }
            });

            if ( $('#completed-date-range-value').val() !== '' ) {
                completedDateRange = $('#completed-date-range-value').val();
            }

            if ( $('#renewal-date-range-value').val() !== '' ) {
                renewalDateRange = $('#renewal-date-range-value').val();
            }

            var href  = '/reports/print-all-ilt';
            href += '/' + courseIDs.join('-');
            href += '/' + instIDs.join('-');
            href += '/' + siteIDs.join('-');
            href += '/' + $('.archive-status-filter input[name="archive-status"]:checked').val();
            href += '/null'; // - NEEDS TO BE PASSED NULL FOR NOW, ARG IS EXPECTED BEFORE DATE RANGES
            href += '/' + completedDateRange;
            href += '/' + renewalDateRange;

            return href;
        },

        makeIndPieCharts : function ( courses ) {
            var chartID, tableID;

            for ( var courseID in courses ) {
                for ( var instID in courses[courseID].locations ) {
                    for ( var siteID in courses[courseID].locations[instID].sites ) {

                        chartID = 'pie-chart-' + courseID + '-' + instID + '-' + siteID;
                        tableID = 'dt-ilt-report-' + courseID + '-' + instID + '-' + siteID;

                        Reports.pieChart.courseTbls[chartID] = $('#' + tableID).DataTable({
                            paging : false,
                            language : {
                                search : 'Filter Results',
                            },
                            order: [[3, 'desc']],
                            columnDefs : [
                                {
                                    orderable : false,
                                    targets : [-3],
                                },
                            ],
                        });

                        if ( courses[courseID].locations[instID].sites[siteID].chartData !== undefined ) {

                            Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                            Reports.pieChart.chartData[courseID + '-' + instID + '-' + siteID] = courses[courseID].locations[instID].sites[siteID].chartData;

                            Reports.pieChart.selectedSectors[chartID] = [];

                            // - INSTANTIATE CHART
                            donutChart.instantiate(
                                Reports.pieChart.charts[chartID],
                                chartID,
                                {
                                    outerRadius : 70,
                                    innerRadius : 0,
                                    centrePoint : {
                                        x : 130,
                                        y : 100,
                                    },
                                    resetBtn : true,
                                    labelMode : 'legend',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                                    animateIn : true,
                                    data : Reports.pieChart.chartData[courseID + '-' + instID + '-' + siteID],
                                    onComplete : function ( chartID ) {
                                        donutChart.initToggleFilter ( chartID, Reports.pieChart.toggleHandler );
                                        donutChart.initResetBtn ( chartID );
                                        TweenMax.to ( '.sector-label', 0.5, {opacity:1} );

                                        $.fn.dataTable.ext.search.push(
                                            function( settings, data, dataIndex ) {
                                                var chartID = 'pie-chart-' +
                                                    $(settings.nTable).attr('data-course-id') + '-' +
                                                    $(settings.nTable).attr('data-inst-id') + '-' +
                                                    $(settings.nTable).attr('data-site-id');

                                                if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                                    return true;
                                                }

                                                for ( var i = 0; i < Reports.pieChart.selectedSectors[chartID].length; i++ ) {
                                                    if ( data[2].indexOf ( 'status::' + Reports.pieChart.selectedSectors[chartID][i] ) !== -1 ) {
                                                        return true;
                                                    }
                                                }

                                                return false;
                                            }
                                        );
                                    },
                                    onCompleteParams : [chartID],
                                }
                            );
                            // - /INSTANTIATE CHART
                        }
                    }
                }
            }
        },

        runExcel : function ( filters, onSuccess ) {
            if ( filters.courses !== null && filters.locations !== null ) {
                // console.log(filters);
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/ilt-courses-excel',
                    data: sendData,
                    type: 'post',
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        // returnedData = JSON.parse(returnedData);
                        // console.log(returnedData);
                        if ( returnedData.success ) {
                            onSuccess ( returnedData );
                        }
                    },
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },
    },

    OC : {

        init : function() {
            $('#ms-courses')
                .off ( 'change' )
                .on ( 'change', function ( evt ) {
                    if ( $(this).val().indexOf ( '-' ) !== -1 ) {
                        $(this).find ( 'option' ).each ( function ( index, elem ) {
                            if ( $(elem).attr ( 'value' ) === '-' ) {
                                $(elem).prop ( 'selected', false );
                            }
                            else {
                                $(elem).prop ( 'selected', true );
                            }
                        });
                    }
                });

            $('#completed-date-range').dateRange({
                mainLabel : 'Completed Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });

            $('#renewal-date-range').dateRange({
                mainLabel : 'Deadline / Renewal Date Range:',
                dateFormat : 'dd/mm/yy',
                altFormat : 'yy-mm-dd',
            });
        },

        destroyTables : function() {
            for ( var chartID in Reports.pieChart.courseTbls ) {
                Reports.pieChart.courseTbls[chartID].destroy();
            }
        },

        getPrintAllLink : function ( reportHTM ) {
            var exLocIDs = [],
                instIDs  = [],
                siteIDs  = [],
                completedDateRange = 'null',// - THESE MUST BE STRINGS AS WE'RE RETURNING THEM AS URL ARGUMENTS
                renewalDateRange   = 'null';

            $('section.report-section').each ( function ( index, elem ) {
                if ( exLocIDs.indexOf ( $(elem).attr ( 'data-course-id' ) ) === -1 ) {
                    exLocIDs.push ( $(elem).attr ( 'data-course-id' ) );
                }

                if ( instIDs.indexOf ( $(elem).attr ( 'data-inst-id' ) ) === -1 ) {
                    instIDs.push ( $(elem).attr ( 'data-inst-id' ) );
                }

                if ( siteIDs.indexOf ( $(elem).attr ( 'data-site-id' ) ) === -1 ) {
                    siteIDs.push ( $(elem).attr ( 'data-site-id' ) );
                }
            });

            if ( $('#completed-date-range-value').val() !== '' ) {
                completedDateRange = $('#completed-date-range-value').val();
            }

            if ( $('#renewal-date-range-value').val() !== '' ) {
                renewalDateRange = $('#renewal-date-range-value').val();
            }

            var href  = '/reports/print-all-oc';
            href += '/' + exLocIDs.join('-');
            href += '/' + instIDs.join('-');
            href += '/' + siteIDs.join('-');
            href += '/' + $('.archive-status-filter input[name="archive-status"]:checked').val();
            href += '/null'; // - NEEDS TO BE PASSED NULL FOR NOW, ARG IS EXPECTED BEFORE DATE RANGES
            href += '/' + completedDateRange;
            href += '/' + renewalDateRange;

            return href;
        },

        makeIndPieCharts : function ( exercises ) {
            // console.log('makeIndPieCharts', exercises);
            var chartID, tableID;

            for ( var exerciseID in exercises ) {
                for ( var instID in exercises[exerciseID].locations ) {
                    for ( var siteID in exercises[exerciseID].locations[instID].sites ) {
                        chartID = 'pie-chart-' + exerciseID + '-' + instID + '-' + siteID;
                        tableID = 'dt-oc-report-' + exerciseID + '-' + instID + '-' + siteID;

                        Reports.pieChart.courseTbls[chartID] = $('#' + tableID).DataTable({
                            paging : false,
                            language : {
                                search : 'Filter Results',
                            },
                            columnDefs : [
                                {
                                    orderable : false,
                                    targets : [-3],
                                },
                            ],
                        });

                        Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                        if ( exercises[exerciseID].locations[instID].sites[siteID].chartData !== undefined ) {

                            Reports.pieChart.chartData[exerciseID + '-' + instID + '-' + siteID] = exercises[exerciseID].locations[instID].sites[siteID].chartData;

                            Reports.pieChart.selectedSectors[chartID] = [];

                            // - INSTANTIATE CHART
                            donutChart.instantiate(
                                Reports.pieChart.charts[chartID],
                                chartID,
                                {
                                    outerRadius : 70,
                                    innerRadius : 0,
                                    centrePoint : {
                                        x : 130,
                                        y : 100,
                                    },
                                    resetBtn : true,
                                    labelMode : 'legend',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                                    animateIn : true,
                                    data : Reports.pieChart.chartData[exerciseID + '-' + instID + '-' + siteID],
                                    onComplete : function ( chartID ) {
                                        donutChart.initToggleFilter ( chartID, Reports.pieChart.toggleHandler );
                                        donutChart.initResetBtn ( chartID );
                                        TweenMax.to ( '.sector-label', 0.5, {opacity:1} );

                                        $.fn.dataTable.ext.search.push(
                                            function( settings, data, dataIndex ) {
                                                var chartID = 'pie-chart-' +
                                                    $(settings.nTable).attr('data-course-id') + '-' +
                                                    $(settings.nTable).attr('data-inst-id') + '-' +
                                                    $(settings.nTable).attr('data-site-id');

                                                if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                                    return true;
                                                }

                                                for ( var i = 0; i < Reports.pieChart.selectedSectors[chartID].length; i++ ) {
                                                    if ( data[6].indexOf ( 'status::' + Reports.pieChart.selectedSectors[chartID][i] ) !== -1 ) {
                                                        return true;
                                                    }
                                                }

                                                return false;
                                            }
                                        );
                                    },
                                    onCompleteParams : [chartID],
                                }
                            );
                            // - /INSTANTIATE CHART
                        }
                    }
                }
            }
        },

        run : function ( filters ) {
            // - reports.OC.run
            var	reportSRC = $('script#report-oc-exercises-tpl').html(),
                reportTPL = Handlebars.compile ( reportSRC ),
                reportHTM;

            if ( filters.courses !== null && filters.locations !== null ) {
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/oc-exercises',
                    data: sendData,
                    type: 'post',
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        // GET THE TERM FOR SITES
                        returnedData.data.terms_tier_2_p = terms.terms_tier_2_p;
                        if ( returnedData.success ) {
                            reportHTM = reportTPL ( returnedData.data );
                            //console.log(reportHTM);
                            $('.report-result').html ( reportHTM );

                            // - GET A LINK FOR PRINT ALL
                            $('.report-result').find('#print-all-oc-btn').attr (
                                'href', Reports.OC.getPrintAllLink ( reportHTM )
                            );

                            Reports.OC.destroyTables();

                            Reports.pieChart.courseTbls      = {};
                            Reports.pieChart.selectedSectors = {};
                            Reports.pieChart.charts          = {};
                            Reports.pieChart.chartData       = {};

                            $.fn.dataTable.ext.search = [];

                            Reports.pieChart.makeOverallPieChart (
                                returnedData.data.overallChart.statusCounts,
                                returnedData.data.overallChart.total,
                                returnedData.data.overallChart.chartData
                            );

                            Reports.pieChart.makeSitePieCharts (
                                returnedData.data.siteCharts
                            );

                            Reports.OC.makeIndPieCharts (
                                returnedData.data.exercises
                            );

                            $('.print-section-btn')
                                .off ( 'click' )
                                .on  ( 'click', function ( evt ) {
                                    var exerciseID = $(this).attr ( 'data-course-id' ),
                                        instID     = $(this).attr ( 'data-inst-id' ),
                                        siteID     = $(this).attr ( 'data-site-id' ),
                                        chartID    = 'pie-chart-' + exerciseID + '-' + instID + '-' + siteID,
                                        statusesSelected,
                                        archiveStatus = $('.archive-status-filter input[name="archive-status"]:checked').val(),
                                        completedDateRange = $(this).attr ( 'data-completed-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-completed-date-range' ),
                                        renewalDateRange = $(this).attr ( 'data-renewal-date-range' ) === '|' ? 'null' : $(this).attr ( 'data-renewal-date-range' ),
                                        href = '/reports/print-single-oc/' + exerciseID + '/' + instID + '/' + siteID + '/' + archiveStatus;

                                    // - IF NO SELECTED SECTORS WE SET IT TO ALL
                                    if ( Reports.pieChart.selectedSectors[chartID].length === 0 ) {
                                        statusesSelected = ['N','I','P','ES','EX'];
                                    }
                                    else {
                                        statusesSelected = Reports.pieChart.selectedSectors[chartID];
                                    }

                                    if ( statusesSelected !== undefined && statusesSelected.length > 0 ) {
                                        href += '/' + statusesSelected.join('-');
                                    }

                                    href += '/' + completedDateRange + '/' + renewalDateRange;

                                    window.open ( href );
                                });
                        }
                    },
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },

        runExcel : function ( filters, onSuccess ) {
            if ( filters.courses !== null && filters.locations !== null ) {
                var sendData = filters;

                $.ajax({
                    url: '/admin/AJAX-reports/oc-exercises-excel',
                    data: sendData,
                    type: 'post',
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        if ( returnedData.success ) {
                            onSuccess ( returnedData );
                        }
                    },
                });
            }
            else {
                main.flashData.show ( 'Please select at least one course and at least one location to generate a report', 'negative', 7 );
            }
        },
        runCmrExcel : function ( filters, onSuccess ) {
            //console.log(filters);
            var sendData = filters;
            // console.log(sendData);
            $.ajax({
                url: '/admin/AJAX-reports/cmr-excel-report',
                data: sendData,
                type: 'post',
                dataType: 'json',
                success: function ( returnedData ) {
                    //console.log(returnedData);
                    if ( returnedData.success ) {
                        onSuccess ( returnedData );
                    }
                },
            });
        },
    },

    companyReport : {
        init : function ( onResponse ) {

            $.ajax({
                url: '/admin/AJAX-reports/get-company-report-peripheral-data',
                dataType: 'json',
                success: function ( returnedData ) {
                    // console.log(returnedData);
                    onResponse ( returnedData );
                },
                error: Errors.ajaxErrorHandler,
            });

        },

        getFilteredRecords : function ( onResponse ) {
            var sendData = {
                selectedItems : Reports.companyReport.filters.selectedItems,
            };
            // console.log('sendData', sendData);
            $.ajax({
                url: '/admin/AJAX-reports/get-filtered-records',
                type: 'post',
                data: sendData,
                dataType: 'json',
                success: function ( returnedData ) {
                    // console.log(returnedData);
                    // - SITES
                    var siteNames = returnedData.filters.sites.siteTotals.map ( function ( v ) {
                        return v.site;
                    });
                    $('#cr-summary--sites').html ( siteNames.join ( ', ' ) );

                    // - TRAINING MODES
                    var tModes = returnedData.filters.trainingModes.tModeTotals.map ( function ( v ) {
                        return Reports.companyReport.filters.trainingModes.tModesObj[v.t_mode];
                    });
                    $('#cr-summary--training-modes').html ( tModes.join ( ', ' ) );

                    // - TRAINING STATUSES
                    var tStatuses = returnedData.filters.trainingStatuses.tStatusTotals.map ( function ( v ) {
                        return Reports.companyReport.filters.trainingStatuses.tStatusesObj[v.t_status];
                    });
                    $('#cr-summary--training-statuses').html ( tStatuses.join ( ', ' ) );

                    // - USERS
                    var users = returnedData.filters.users.userTotals.map ( function ( v ) {
                        return v.first_name + ' ' + v.surname;
                    });
                    $('#cr-summary--users').html ( users.join ( ', ' ) );

                    Reports.companyReport.results.populateResultsByCourse ( returnedData );
                    Reports.companyReport.results.populateResultsByUser ( returnedData );

                    onResponse();
                },
                error: Errors.ajaxErrorHandler,
            });
        },

        results : {
            populateResultsByCourse : function ( response ) {

            },

            populateResultsByUser : function ( response ) {
                // console.log('populateResultsByUser', response);

                // - USERS

                var userID;

                Reports.companyReport.results.users = {};
                for ( var i = 0; i < response.filters.users.userTotals.length; i++ ) {
                    // - SET THE USER ID
                    userID = response.filters.users.userTotals[i].user_id;
                    // - FILTER THE USERS
                    Reports.companyReport.results.users[userID] = {
                        user_id : response.filters.users.userTotals[i].user_id,
                        first_name : response.filters.users.userTotals[i].first_name,
                        surname : response.filters.users.userTotals[i].surname,
                        courses : response.data.filter ( function ( record ) {
                            return record.user_id == userID;
                        }),
                    };
                }

                var	reportByUserSRC = $('script#cr-by-user-tpl').html(),
                    reportByUserTPL = Handlebars.compile ( reportByUserSRC ),
                    reportByUserHTM = reportByUserTPL ( Reports.companyReport.results );

                $('#cr-results-by-user').html ( reportByUserHTM );

                $('.cr-results-table').dataTable();
            },
        },

        filters : {
            selectedItems : {sites:[], trainingModes:[], trainingStatuses:[], users:[]},

            refreshEvts : function() {
                Reports.companyReport.filters.sites.refreshCheckboxEvts();
                Reports.companyReport.filters.trainingModes.refreshCheckboxEvts();
                Reports.companyReport.filters.trainingStatuses.refreshCheckboxEvts();
                Reports.companyReport.filters.users.refreshCheckboxEvts();
            },

            updateFilterTotals : function ( filters ) {
                var sendData = {
                    selectedItems : Reports.companyReport.filters.selectedItems,
                };
                // console.log('sendData', sendData);
                $.ajax({
                    url: '/admin/AJAX-reports/update-filter-totals-by-selection',
                    type: 'post',
                    data: sendData,
                    dataType: 'json',
                    success: function ( returnedData ) {
                        // console.log(returnedData);
                        Reports.companyReport.filters.sites.overDrawFilters (
                            returnedData.filters.sites.siteTotals, true
                        );

                        Reports.companyReport.filters.trainingModes.overDrawFilters (
                            returnedData.filters.trainingModes.tModeTotals, true
                        );

                        Reports.companyReport.filters.trainingStatuses.overDrawFilters (
                            returnedData.filters.trainingStatuses.tStatusTotals, true
                        );

                        Reports.companyReport.filters.users.overDrawFilters (
                            returnedData.filters.users.userTotals, true
                        );

                        Reports.companyReport.filters.updateChart (returnedData.filters.trainingStatuses.tStatusTotals );
                    },
                    error: Errors.ajaxErrorHandler,
                });

            },

            updateChart : function ( tStatusTotals ) {
                /////////////////////////
                //     DONUT UPDATE    //
                /////////////////////////

                var chartID = 'cr-company';

                Reports.pieChart.charts[chartID] = Snap ( '#' + chartID );

                var chartData = [
                    {
                        "sectorClass":"sector-0",
                        "selector":"#cr-company .sector-0",
                        "percent":0.0277777777778,
                        "amount":2,
                        "strokeColour":"#F4F4F4",
                        "colour":"#FFFFFF",
                        "label-colour":"#000000",
                        "label":"Not Started",
                        "filter":"N",
                        "startD":0,
                        "endD":10.000000000008,
                        "startPercent":0,
                        "endPercent":0.0277777777778,
                        "midPercent":0.0138888888889,
                        "midD":5.000000000004
                    },
                    {
                        "sectorClass":"sector-1",
                        "selector":"#cr-company .sector-1",
                        "percent":0.0555555555556,
                        "amount":4,
                        "strokeColour":"#F0D500",
                        "colour":"#FFE300",
                        "label-colour":"#000000",
                        "label":"In Progress",
                        "filter":"I",
                        "startD":10.000000000008,
                        "endD":30.000000000024002,
                        "startPercent":0.0277777777778,
                        "endPercent":0.08333333333340001,
                        "midPercent":0.0555555555556,
                        "midD":20.000000000016
                    },
                    {
                        "sectorClass":"sector-2",
                        "selector":"#cr-company .sector-2",
                        "percent":0.916666666667,
                        "amount":66,
                        "strokeColour":"#008222",
                        "colour":"#009933",
                        "label-colour":"#000000",
                        "label":"Complete",
                        "filter":"P",
                        "startD":30.000000000024002,
                        "endD":360.000000000144,
                        "startPercent":0.08333333333340001,
                        "endPercent":1.0000000000004001,
                        "midPercent":0.5416666666669001,
                        "midD":195.00000000008404
                    },
                    {
                        "sectorClass":"sector-3",
                        "selector":"#cr-company .sector-3",
                        "percent":0,
                        "amount":0,
                        "strokeColour":"#F08200",
                        "colour":"#FF9900",
                        "label-colour":"#000000",
                        "label":"Expiring Soon",
                        "filter":"ES",
                        "startD":360.000000000144,
                        "endD":360.000000000144,
                        "startPercent":1.0000000000004001,
                        "endPercent":1.0000000000004001,
                        "midPercent":1.0000000000004001,
                        "midD":360.00000000014404
                    },
                    {
                        "sectorClass":"sector-4",
                        "selector":"#cr-company .sector-4",
                        "percent":0,
                        "amount":0,
                        "strokeColour":"#DD0000",
                        "colour":"#EE0000",
                        "label-colour":"#000000",
                        "label":"Expired",
                        "filter":"EX",
                        "startD":360.000000000144,
                        "endD":360.000000000144,
                        "startPercent":1.0000000000004001,
                        "endPercent":1.0000000000004001,
                        "midPercent":1.0000000000004001,
                        "midD":360.00000000014404
                    }
                ];

                var totalOfStatuses = tStatusTotals.reduce(function ( running, num ) {
                    return {total : Number(num.total) + Number(running.total)};
                });

                var chartPercs = chartData.map ( function ( item ) {
                    var statusKey = item.filter;
                    var amount = 0;
                    for ( var i = 0; i < tStatusTotals.length; i++ ) {
                        if ( tStatusTotals[i].t_status === statusKey ) {
                            amount = tStatusTotals[i].total;
                        }
                    }
                    return {amount:amount, percent:amount/totalOfStatuses.total};
                });

                var currDegrees = 0;
                var currPercent = 0;
                for ( var i = 0; i < chartData.length; i++ ) {
                    chartData[i].amount  = Number(chartPercs[i].amount);
                    chartData[i].percent = chartPercs[i].percent;
                    chartData[i].startDeg = currDegrees;
                    chartData[i].startPercent = currPercent;
                    currDegrees += chartPercs[i].percent * 360;
                    currPercent += chartPercs[i].percent;
                    chartData[i].endDeg = currDegrees;
                    chartData[i].endPercent = currPercent;
                    chartData[i].midDeg = (chartData[i].startDeg + chartData[i].endDeg) / 2;
                    chartData[i].midPercent = (chartData[i].startPercent + chartData[i].endPercent) / 2;
                    // console.log(chartData[i]);
                    // console.log('sP: ', chartData[i].startPercent, 'eP: ', chartData[i].endPercent);
                    // console.log('sD: ', chartData[i].startDeg, 'eD: ', chartData[i].endDeg);
                }
                // console.log(chartData);

                donutChart.instantiate (
                    Reports.pieChart.charts[chartID],
                    chartID,
                    {
                        outerRadius : 120,
                        innerRadius : 0,
                        centrePoint : {
                            x : 0,
                            y : 150,
                        },
                        resetBtn : true,
                        labelMode : 'legend',// - NOT USED BUT WHEN AN ALTERNATIVE LABEL THAT RUNS ALONG THE SECTOR EDGE IS DONE WE'LL NEED TO DISTINGUISH central-tangent|legend
                        legendXOff : 260,
                        legendYOff : 75,
                        animateIn : true,
                        data : chartData,
                        onComplete : function ( chartID ) {
                            // donutChart.initToggleFilter ( chartID, Reports.pieChart.overallToggleHandler );
                            donutChart.initResetBtn ( chartID );
                            TweenMax.to ( '.sector-label', 0.5, {opacity:1} );
                        },
                        onCompleteParams : [chartID],
                    }
                );
            },

            sites : {
                refreshCheckboxEvts : function() {
                    $('.cb-cr-site')
                        .off ( 'change' )
                        .on  ( 'change', function ( evt ) {
                            if ( $(this).prop ( 'checked' ) ) {
                                if ( Reports.companyReport.filters.selectedItems.sites.indexOf ( $(this).attr('data-value') ) === -1 ) {
                                    Reports.companyReport.filters.selectedItems.sites.push ( $(this).attr('data-value') );
                                }
                            }
                            else {
                                var position = Reports.companyReport.filters.selectedItems.sites.indexOf ( $(this).attr('data-value') );
                                if ( position !== -1 ) {
                                    Reports.companyReport.filters.selectedItems.sites.splice ( position, 1 );
                                }
                            }

                            Reports.companyReport.filters.updateFilterTotals ( Reports.companyReport.filters.selectedItems );
                        });
                },

                drawFilters : function ( siteTotals ) {
                    $('.cb-cr-group--sites').empty();

                    for ( var i = 0; i < siteTotals.length; i++ ) {
                        $('.cb-cr-group--sites').append (
                            $('<li></li>')
                            .append (
                                $('<label></label>')
                                .attr ( 'for', 'cb-cr-site-' + siteTotals[i].site_id )
                                .append ([
                                    $('<input>')
                                    .attr ( 'type', 'checkbox' )
                                    .attr ( 'id', 'cb-cr-site-' + siteTotals[i].site_id )
                                    .addClass ( 'cb-cr-site cb-cr-filter' )
                                    .attr ( 'data-value', siteTotals[i].site_id )
                                    .prop ( 'checked', false ),
                                    '&nbsp;',
                                    $('<span>')
                                    .append ([
                                        siteTotals[i].site,
                                        $('<span>')
                                        .addClass ( 'cb-cr-total' )
                                        .text ( '(' + siteTotals[i].total + ')' )
                                    ])
                                ])
                            )
                        );
                    }
                },

                overDrawFilters : function ( siteTotals, _zeroMissing ) {
                    // - BEHAVES SIMILARLY TO drawFilters BUT ONLY OVERWRITES FILTERS WE HAVE NEW VALUES FOR, IF zeroMissing = true WE SET ALL OTHER VALUES NOT SPECIFIED TO ZERO

                    var zeroMissing = false;
                    if ( _zeroMissing !== undefined ) {
                        zeroMissing = _zeroMissing;
                    }

                    if ( zeroMissing ) {
                        $('label[for*="cb-cr-site-"] .cb-cr-total').each ( function ( index, elem ) {
                            $(elem).text ( '(0)' );
                        });
                    }

                    for ( var i = 0; i < siteTotals.length; i++ ) {
                        $('label[for="cb-cr-site-' + siteTotals[i].site_id + '"] .cb-cr-total').text ( '(' + siteTotals[i].total + ')' );
                        $('label[for="cb-cr-site-' + siteTotals[i].site_id + '"] .cb-cr-total').flashNum();
                    }
                },

            },

            trainingModes : {
                tModesObj : {
                    OL  : 'Online Learning',
                    OC  : '<abbr title="Occupational Competency">Occ. Competency</abbr>',
                    ILT : 'ILT',
                    TPQ : 'TPaQ',
                },

                refreshCheckboxEvts : function() {
                    $('.cb-cr-training-mode')
                        .off ( 'change' )
                        .on  ( 'change', function ( evt ) {
                            if ( $(this).prop ( 'checked' ) ) {
                                if ( Reports.companyReport.filters.selectedItems.trainingModes.indexOf ( $(this).attr('data-value') ) === -1 ) {
                                    Reports.companyReport.filters.selectedItems.trainingModes.push ( $(this).attr('data-value') );
                                }
                            }
                            else {
                                var position = Reports.companyReport.filters.selectedItems.trainingModes.indexOf ( $(this).attr('data-value') );
                                if ( position !== -1 ) {
                                    Reports.companyReport.filters.selectedItems.trainingModes.splice ( position, 1 );
                                }
                            }

                            Reports.companyReport.filters.updateFilterTotals ( Reports.companyReport.filters.selectedItems );
                        });
                },

                drawFilters : function ( tModeTotals ) {
                    $('.cb-cr-group--training-modes').empty();

                    for ( var i = 0; i < tModeTotals.length; i++ ) {
                        $('.cb-cr-group--training-modes').append (
                            $('<li></li>')
                            .append (
                                $('<label></label>')
                                .attr ( 'for', 'cb-cr-training-mode-' + tModeTotals[i].t_mode )
                                .append ([
                                    $('<input>')
                                    .attr ( 'type', 'checkbox' )
                                    .attr ( 'id', 'cb-cr-training-mode-' + tModeTotals[i].t_mode )
                                    .addClass ( 'cb-cr-training-mode cb-cr-filter' )
                                    .attr ( 'data-value', tModeTotals[i].t_mode )
                                    .prop ( 'checked', false ),
                                    '&nbsp;',
                                    $('<span>')
                                    .append ([
                                        Reports.companyReport.filters.trainingModes.tModesObj[tModeTotals[i].t_mode],
                                        $('<span>')
                                        .addClass ( 'cb-cr-total' )
                                        .text ( '(' + tModeTotals[i].total + ')' )
                                    ])
                                ])
                            )
                        );
                    }
                },

                overDrawFilters : function ( tModeTotals, _zeroMissing ) {
                    // - BEHAVES SIMILARLY TO drawFilters BUT ONLY OVERWRITES FILTERS WE HAVE NEW VALUES FOR, IF zeroMissing = true WE SET ALL OTHER VALUES NOT SPECIFIED TO ZERO

                    var zeroMissing = false;
                    if ( _zeroMissing !== undefined ) {
                        zeroMissing = _zeroMissing;
                    }

                    if ( zeroMissing ) {
                        $('label[for*="cb-cr-training-mode-"] .cb-cr-total').each ( function ( index, elem ) {
                            $(elem).text ( '(0)' );
                        });
                    }

                    for ( var i = 0; i < tModeTotals.length; i++ ) {
                        $('label[for="cb-cr-training-mode-' + tModeTotals[i].t_mode + '"] .cb-cr-total').text ( '(' + tModeTotals[i].total + ')' );
                        $('label[for="cb-cr-training-mode-' + tModeTotals[i].t_mode + '"] .cb-cr-total').flashNum();
                    }
                },

            },

            trainingStatuses : {
                tStatusesObj : {
                    N  : 'Not Started',
                    I  : 'In Progress',
                    P  : 'Passed',
                    ES : 'Expiring Soon',
                    EX : 'Expired',
                },

                refreshCheckboxEvts : function() {
                    $('.cb-cr-training-status')
                        .off ( 'change' )
                        .on  ( 'change', function ( evt ) {
                            if ( $(this).prop ( 'checked' ) ) {
                                if ( Reports.companyReport.filters.selectedItems.trainingStatuses.indexOf ( $(this).attr('data-value') ) === -1 ) {
                                    Reports.companyReport.filters.selectedItems.trainingStatuses.push ( $(this).attr('data-value') );
                                }
                            }
                            else {
                                var position = Reports.companyReport.filters.selectedItems.trainingStatuses.indexOf ( $(this).attr('data-value') );
                                if ( position !== -1 ) {
                                    Reports.companyReport.filters.selectedItems.trainingStatuses.splice ( position, 1 );
                                }
                            }

                            Reports.companyReport.filters.updateFilterTotals ( Reports.companyReport.filters.selectedItems );
                        });
                },

                drawFilters : function ( tStatusTotals ) {
                    $('.cb-cr-group--training-statuses').empty();

                    for ( var i = 0; i < tStatusTotals.length; i++ ) {
                        $('.cb-cr-group--training-statuses').append (
                            $('<li></li>')
                            .append (
                                $('<label></label>')
                                .attr ( 'for', 'cb-cr-training-status-' + tStatusTotals[i].t_status )
                                .append ([
                                    $('<input>')
                                    .attr ( 'type', 'checkbox' )
                                    .attr ( 'id', 'cb-cr-training-status-' + tStatusTotals[i].t_status )
                                    .addClass ( 'cb-cr-training-status cb-cr-filter' )
                                    .attr ( 'data-value', tStatusTotals[i].t_status )
                                    .prop ( 'checked', false ),
                                    '&nbsp;',
                                    $('<span>')
                                    .append ([
                                        Reports.companyReport.filters.trainingStatuses.tStatusesObj[tStatusTotals[i].t_status],
                                        $('<span>')
                                        .addClass ( 'cb-cr-total' )
                                        .text ( '(' + tStatusTotals[i].total + ')' )
                                    ])
                                ])
                            )
                        );
                    }
                },

                    overDrawFilters : function ( tStatusTotals, _zeroMissing ) {
                        // - BEHAVES SIMILARLY TO drawFilters BUT ONLY OVERWRITES FILTERS WE HAVE NEW VALUES FOR, IF zeroMissing = true WE SET ALL OTHER VALUES NOT SPECIFIED TO ZERO

                        var zeroMissing = false;
                        if ( _zeroMissing !== undefined ) {
                            zeroMissing = _zeroMissing;
                        }

                        if ( zeroMissing ) {
                            $('label[for*="cb-cr-training-status-"] .cb-cr-total').each ( function ( index, elem ) {
                                $(elem).text ( '(0)' );
                            });
                        }

                        for ( var i = 0; i < tStatusTotals.length; i++ ) {
                            $('label[for="cb-cr-training-status-' + tStatusTotals[i].t_status + '"] .cb-cr-total').text ( '(' + tStatusTotals[i].total + ')' );
                            $('label[for="cb-cr-training-status-' + tStatusTotals[i].t_status + '"] .cb-cr-total').flashNum();
                        }
                    },

            },

                users : {
                    refreshCheckboxEvts : function() {
                        $('.cb-cr-user')
                            .off ( 'change' )
                            .on  ( 'change', function ( evt ) {
                                if ( $(this).prop ( 'checked' ) ) {
                                    if ( Reports.companyReport.filters.selectedItems.users.indexOf ( $(this).attr('data-value') ) === -1 ) {
                                        Reports.companyReport.filters.selectedItems.users.push ( $(this).attr('data-value') );
                                    }
                                }
                                else {
                                    var position = Reports.companyReport.filters.selectedItems.users.indexOf ( $(this).attr('data-value') );
                                    if ( position !== -1 ) {
                                        Reports.companyReport.filters.selectedItems.users.splice ( position, 1 );
                                    }
                                }

                                Reports.companyReport.filters.updateFilterTotals ( Reports.companyReport.filters.selectedItems );
                            });
                    },

                        drawFilters : function ( userTotals ) {
                            $('.cb-cr-group--users').empty();

                            for ( var i = 0; i < userTotals.length; i++ ) {
                                $('.cb-cr-group--users').append (
                                    $('<li></li>')
                                    .append (
                                        $('<label></label>')
                                        .attr ( 'for', 'cb-cr-user-' + userTotals[i].user_id )
                                        .append ([
                                            $('<input>')
                                            .attr ( 'type', 'checkbox' )
                                            .attr ( 'id', 'cb-cr-user-' + userTotals[i].user_id )
                                            .addClass ( 'cb-cr-user cb-cr-filter' )
                                            .attr ( 'data-value', userTotals[i].user_id )
                                            .prop ( 'checked', false ),
                                            '&nbsp;',
                                            $('<span>')
                                            .append ([
                                                userTotals[i].first_name + ' ' + userTotals[i].surname,
                                                $('<span>')
                                                .addClass ( 'cb-cr-total' )
                                                .text ( '(' + userTotals[i].total + ')' )
                                            ])
                                        ])
                                    )
                                );
                            }
                        },

                        overDrawFilters : function ( userTotals, _zeroMissing ) {
                            // - BEHAVES SIMILARLY TO drawFilters BUT ONLY OVERWRITES FILTERS WE HAVE NEW VALUES FOR, IF zeroMissing = true WE SET ALL OTHER VALUES NOT SPECIFIED TO ZERO

                            var zeroMissing = false;
                            if ( _zeroMissing !== undefined ) {
                                zeroMissing = _zeroMissing;
                            }

                            if ( zeroMissing ) {
                                $('label[for*="cb-cr-user-"] .cb-cr-total').each ( function ( index, elem ) {
                                    $(elem).text ( '(0)' );
                                });
                            }

                            for ( var i = 0; i < userTotals.length; i++ ) {
                                $('label[for="cb-cr-user-' + userTotals[i].user_id + '"] .cb-cr-total').text ( '(' + userTotals[i].total + ')' );
                                $('label[for="cb-cr-user-' + userTotals[i].user_id + '"] .cb-cr-total').flashNum();
                            }
                        },

                },

        },
    },
};
